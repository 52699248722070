<template>
    <div>
        <v-card :flat="$vuetify.theme.isDark">
            <v-card-title class="mb-2">
                <v-btn
                    text
                    :disabled="!adminRole && !operatonAddRole"
                    outlined
                    depressed
                    color="secondary"
                    @click="openAddDialog()"
                >
                    <v-icon class="ml-1">mdi-plus-circle</v-icon>
                    اضـافة عملية جديدة
                </v-btn>
                <v-divider class="mx-4 my-1" vertical> </v-divider>
                <v-spacer></v-spacer>

                <search-in-data-table />
            </v-card-title>

            <v-data-table
                :headers="headers"
                :items="items"
                :item-class="itemRowBackground"
                loader-height="10"
                :search="search"
                :loading="loading"
                no-data-text="لا توجد بيانات"
                loading-text="جاري تحميل البيانات"
                class="elevation-1"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event"
            >
                <template v-slot:[`item.reservationReservationNo`]="{ item }">
                    <span v-if="item.reservationReservationNo == 0">
                        لا يوجد حجز
                    </span>
                    <span v-else> {{ item.reservationReservationNo }} </span>
                </template>

                <template v-slot:[`item.operationType`]="{ item }">
                    <v-chip
                        small
                        v-if="item.operationType == 1"
                        color="green"
                        class="white--text"
                    >
                        تنظيف
                    </v-chip>
                    <v-chip
                        small
                        v-if="item.operationType == 2"
                        color="blue"
                        class="white--text"
                    >
                        صيانة
                    </v-chip>
                </template>

                <template v-slot:[`item.roomRoomNumber`]="{ item }">
                    <v-chip color="blue" class="white--text">
                        {{ item.roomRoomNumber }}
                    </v-chip>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                        small
                        color="blue"
                        class="white--text"
                        v-if="item.status"
                        >مكتملة</v-chip
                    >
                    <v-chip
                        small
                        color="blue"
                        class="white--text"
                        v-if="item.status == false && item.operationType == 1"
                    >
                        جاري التنظيف</v-chip
                    >
                    <v-chip
                        small
                        color="error"
                        class="white--text"
                        v-if="item.status == false && item.operationType == 2"
                        >تحت الصيانة</v-chip
                    >
                </template>

                <template v-slot:[`item.createdDate`]="{ item }">
                    <span class="blue--text">
                        {{ item.createdDate | formatDate }}
                    </span>
                </template>

                <template v-slot:[`item.modifiedDate`]="{ item }">
                    <span class="error--text">
                        {{ item.modifiedDate | formatDate }}
                    </span>
                    <span v-if="item.modifiedDate == null" class="error--text">
                        ليس بعد
                    </span>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                fab
                                depressed
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="openDialogoPerstionsRoom(item)"
                                :disabled="!adminRole && !operatonAddRole"
                            >
                                <v-list-item-title class="green--text">
                                    <v-icon color="green" class="ml-1">
                                        mdi-minus-circle-off-outline
                                    </v-icon>
                                    انهاء العملية
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="openEditDialog(item)">
                                <v-list-item-title>
                                    <v-icon class="ml-2" color="secondary">
                                        mdi-pencil-box-multiple
                                    </v-icon>
                                    تعديــل
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                @click="deleteItem(item.id)"
                                :disabled="!adminRole && !operatonDeleteRole"
                            >
                                <v-list-item-title class="red--text">
                                    <v-icon color="red" class="ml-1">
                                        mdi-delete-alert-outline
                                    </v-icon>
                                    حذف العملية
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>

            <v-divider></v-divider>

            <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
                class="py-6"
            >
            </v-pagination>
        </v-card>

        <DialogAdd ref="dialogAdd" />
        <DialogOffOpertionRoom ref="dialog" />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SearchInDataTable from "../../components/SearchInDataTable.vue";

import DialogAdd from "./DialogAdd.vue";
import DialogOffOpertionRoom from "./DialogOffOpertionRoom.vue";

export default {
    data() {
        return {
            loading: false,
            isfullscreen: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 13,
            headers: [
                {
                    text: "رقم الحجز  ",
                    value: "reservationReservationNo"
                },
                {
                    text: "اسم الموظف",
                    value: "staffFullName"
                },
                {
                    text: "نوع العملية",
                    value: "operationType"
                },
                {
                    text: "رقم الغرفة",
                    value: "roomRoomNumber"
                },
                {
                    text: "اسم الموظف المشرف",
                    value: "confirmStaffFullName"
                },
                {
                    text: "الحالة",
                    value: "status"
                },
                {
                    text: "ملاحضة",
                    value: "notes"
                },
                {
                    text: "المستخدم الذي قام بالاضافة",
                    value: "createdUserUserName"
                },
                {
                    text: "تاريخ الاضافة",
                    value: "createdDate"
                },
                {
                    text: "الاجراءات",
                    value: "actions",
                    align: "left",
                    sortable: false
                }
            ],
            items: []
        };
    },
    components: {
        DialogAdd,
        SearchInDataTable,
        DialogOffOpertionRoom
    },

    computed: {
        ...mapState(["search", "mini_drawer"]),
        ...mapGetters("roles", [
            "adminRole",
            "operatonAddRole",
            "operatonEditRole",
            "operatonDeleteRole"
        ])
    },

    created() {
        this.$eventBus.$on(this.$route.path, () => {
            this.getItems();
        });
        this.getItems();
        this.$eventBus.$on("openDirectOperatons", () => {
            this.openAddDialog();
        });
    },

    methods: {
        getItems() {
            this.loading = true;
            this.$GenericService
                .getAll("operatonsRoom/getOperatonsRooms")
                .then(res => {
                    this.items = res.data.data;
                    this.loading = false;
                })
                .catch(err => {});
        },

        deleteItem(id) {
            this.$GenericService.swalAlertConfirm({}).then(result => {
                if (result.isConfirmed) {
                    this.$GenericService
                        .delete(`operatonsRoom/${id}`)
                        .then(res => {
                            this.getItems();
                        })
                        .catch(err => {});
                    this.$GenericServicertSuccess();
                }
            });
        },

        openDialogoPerstionsRoom(item) {
            this.$store.dispatch("setDialogoPerstionsRoom");
            item.dialogType = "edit";
            this.$store.dispatch("setBodyDetails", item);
            this.$eventBus.$emit("fill-fields");
            console.log(item);
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.dispatch("setBodyDetailsOpertionRoom", item);
            this.$store.dispatch("setDialogOpertionRoom");
            this.$eventBus.$emit("fill-fields");
        },

        openAddDialog() {
            var item = {
                dialogType: "add"
            };
            this.$store.dispatch("setBodyDetailsOpertionRoom", item);
            this.$store.dispatch("setDialogOpertionRoom");
        },

        itemRowBackground: function(item) {
            return item.status ? "style-1" : "";
        }
    }
};
</script>
