<template>
    <div>
        <v-dialog
            v-model="$store.state.dialogOffOpertionRoom"
            width="40%"
            persistent=""
        >
            <v-card flat>
                <v-col>
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>
                            انهاء العملية باشراف موظف
                        </v-toolbar-title>
                        <v-spacer />
                        <v-btn @click="close()" color="error">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-form
                        @submit.prevent="submit()"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row no-gutters class="my-7">
                            <v-col cols="12" md="12">
                                اختار الموظف المشرف على انهاء, تنظيف او صيانة
                                الغرفة
                                <span class="red--text font-weight-bold"
                                    >*</span
                                >
                                <staffs-list
                                    serviceType="3"
                                    label="اختار الموظف المشرف على انهاء تنظف او صيانة الغرفة  "
                                />
                            </v-col>

                            <v-col cols="12" md="12" class="mt-8">
                                <v-btn
                                    type="submit"
                                    :disabled="!$store.state.staffId"
                                    x-large
                                    color="primary"
                                >
                                    حفظ
                                </v-btn>
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2"
                                    outlined
                                >
                                    إلغاء
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import validations from "@/utils/validations";
import StaffsList from "../../components/lists/StaffsList.vue";
import { mapState } from "vuex";

export default {
    data: () => ({
        value: 1234,
        valid: true,
        body: {},
        loading: false,
        ...validations
    }),
    components: {
        StaffsList
    },
    computed: {
        ...mapState(["bodyDetails"])
    },

    created() {
        this.body = {};
        // this.getRooms();
    },

    mounted() {
        this.$eventBus.$on("fill-fields", () => {
            this.body = this.bodyDetails;
            // this.$store.state.staffId = this.$store.state.bodyDetails.staffId;
        });
    },

    methods: {
        submit() {
            if (this.bodyDetails.dialogType == "add") {
                this.addItem();
            } else {
                this.editItem();
            }
        },

        editItem() {
            this.loading = true;
            this.body.confirmStaffId = this.$store.state.staffId;

            let data = {
                confirmStaffId: this.$store.state.staffId
            };

            this.$GenericService
                .update(`operatonsRoom/offOpertionRoom/${this.body.id}`, data)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم التعديل بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        close() {
            this.$store.dispatch("setDialogoPerstionsRoom");
            this.body = {};
            this.$store.state.staffId = null;
            this.resetValidation();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
</script>
