var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":_vm.$vuetify.theme.isDark}},[_c('v-card-title',{staticClass:"mb-2"},[_c('v-btn',{attrs:{"text":"","disabled":!_vm.adminRole && !_vm.operatonAddRole,"outlined":"","depressed":"","color":"secondary"},on:{"click":function($event){return _vm.openAddDialog()}}},[_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-plus-circle")]),_vm._v(" اضـافة عملية جديدة ")],1),_c('v-divider',{staticClass:"mx-4 my-1",attrs:{"vertical":""}}),_c('v-spacer'),_c('search-in-data-table')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-class":_vm.itemRowBackground,"loader-height":"10","search":_vm.search,"loading":_vm.loading,"no-data-text":"لا توجد بيانات","loading-text":"جاري تحميل البيانات","hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.reservationReservationNo",fn:function(ref){
var item = ref.item;
return [(item.reservationReservationNo == 0)?_c('span',[_vm._v(" لا يوجد حجز ")]):_c('span',[_vm._v(" "+_vm._s(item.reservationReservationNo)+" ")])]}},{key:"item.operationType",fn:function(ref){
var item = ref.item;
return [(item.operationType == 1)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"green"}},[_vm._v(" تنظيف ")]):_vm._e(),(item.operationType == 2)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"blue"}},[_vm._v(" صيانة ")]):_vm._e()]}},{key:"item.roomRoomNumber",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(item.roomRoomNumber)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"blue"}},[_vm._v("مكتملة")]):_vm._e(),(item.status == false && item.operationType == 1)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"blue"}},[_vm._v(" جاري التنظيف")]):_vm._e(),(item.status == false && item.operationType == 2)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"error"}},[_vm._v("تحت الصيانة")]):_vm._e()]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdDate))+" ")])]}},{key:"item.modifiedDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.modifiedDate))+" ")]),(item.modifiedDate == null)?_c('span',{staticClass:"error--text"},[_vm._v(" ليس بعد ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","fab":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!_vm.adminRole && !_vm.operatonAddRole},on:{"click":function($event){return _vm.openDialogoPerstionsRoom(item)}}},[_c('v-list-item-title',{staticClass:"green--text"},[_c('v-icon',{staticClass:"ml-1",attrs:{"color":"green"}},[_vm._v(" mdi-minus-circle-off-outline ")]),_vm._v(" انهاء العملية ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"ml-2",attrs:{"color":"secondary"}},[_vm._v(" mdi-pencil-box-multiple ")]),_vm._v(" تعديــل ")],1)],1),_c('v-list-item',{attrs:{"disabled":!_vm.adminRole && !_vm.operatonDeleteRole},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-list-item-title',{staticClass:"red--text"},[_c('v-icon',{staticClass:"ml-1",attrs:{"color":"red"}},[_vm._v(" mdi-delete-alert-outline ")]),_vm._v(" حذف العملية ")],1)],1)],1)],1)]}}],null,true)}),_c('v-divider'),_c('v-pagination',{staticClass:"py-6",attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('DialogAdd',{ref:"dialogAdd"}),_c('DialogOffOpertionRoom',{ref:"dialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }